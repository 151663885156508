<div class="container" style="padding-top:100px;margin-bottom:200px;"> 
  <div class="row row-cols-3">

    <div class="col offset-4">

      <div class=" card mat-elevation-z5 mt-5" [formGroup]="authForm">

        <div class="card-header d-flex justify-content-center">
          <mat-card-title>
            Multifactor Authentication (MFA)
          </mat-card-title>
        </div>

        <div class="card-body">
          <section class="row">
            <section class="col">
              
                <p  class="text-left"><strong>Please enter the Multifactor Authentication (MFA) PIN you received in email.</strong></p>
                
                <p  class="text-left">The MFA PIN will expire in {{expirationTimeInMinutes}} minutes. Please check your spam folder if you don’t see it in the inbox.</p>

                <p  class="text-left">Click on the <b>Resend PIN</b> to receive a newly generated MFA PIN.</p>                
                          
            </section>
          </section>
          <div class="row ">

            <mat-form-field class="col" appearance="outline">
              <mat-label>MFA PIN</mat-label>
              <input id="mfaCode" matInput placeholder="MFA PIN" maxlength="6" formControlName="MFACode" type="text" required>              
              <mat-error id="mfaCodeInvalidError" *ngIf="authForm.get('MFACode')?.invalid && authForm.get('MFACode').value != ''">
                Please enter valid characters
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col d-flex justify-content-center">
            <button mat-raised-button [disabled]="authForm.get('MFACode')?.invalid || processing"  color="primary" class="mr-2" (click)="onMfaCodeSubmit()">Submit PIN</button>
            <button mat-raised-button  class="mr-2" color="warn" (click)="onResendMfaCode()">Resend PIN</button>
          </div>          
          <br/>
          
         
          <div class="row justify-content-center align-content-center">
            <a routerLink='' class="ml-1" href="#" (click)="onBackToLogin()">Back to Login</a>
          </div>

          
          
        </div>

      </div>

    </div>
    

  </div>

</div>


