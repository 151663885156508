import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FacilityService } from './facility.service';
import { PharmacyService } from './pharmacy.service';
import { VaccineContext } from '../models/vaccine-context.model';
import {DataService, LoaderService } from '../../shared';
import { MessageBoxService } from '../../shared/services/message-box.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(
    private router: Router,
    private _facilityService: FacilityService,
    private _pharmacyService: PharmacyService,
    private _dateService: DataService,
    private _msgService: MessageBoxService,
    private loaderService: LoaderService
  ) { }


  navigateToViewType(context: VaccineContext) {
    if (context != null && context.contextUser != null) {
      this._dateService.vaccineContext.setContext(context);
      if (context.contextUser.passwordResetToken == 'Y') {
        this._dateService.vaccineContext.setContext(context);
        this.router.navigate(['change-password']);
        return;
      }
    }

    //set context institution
    //set facility
    if (['nf', 'rcf', 'aaa', 'icf'].indexOf(context.viewType) >= 0) {
      this._facilityService.selectFacility(context.contextUser.institutionId).subscribe(fac => {
        if (fac != null) {
          context.contextFacility = fac;
          this.loaderService.showLoader();
        } else {
          this._msgService.error({
            title: "Error",
            message: "No facility found associated with this account."
          });
          return;
        }
        this._dateService.vaccineContext.setContext(context);
        switch (context.viewType) {
          case 'icf':
            this.router.navigate(['facility-home']);
            break;
          default:
            this.router.navigate(['facility-home']);
        }
      });
    }

    //dodd admin
    if (context.viewType == 'doddAdmin') {
      this._dateService.vaccineContext.setContext(context);
      this.loaderService.showLoader();
      // this.router.navigate(['vaccine-schedule/admin']);
      //this.router.navigate(['dodd-admin']);
      this.router.navigate(['facility-admin']);
    }

    //set pharmacy
    if (context.viewType == 'pharmacy') {
      this._pharmacyService.getPharmacy(context.contextUser.institutionId).subscribe(ph => {
        if (ph != null) {
          context.contextPharmacy = ph;
          this._dateService.vaccineContext.setContext(context);
          this.loaderService.showLoader();
          this.router.navigate(['facility-home/dashboard']);
        } else {

        }
      });
    }

    //admin
    if (context.viewType == 'admin') {
      this._dateService.vaccineContext.setContext(context);
      this.loaderService.showLoader();
      // this.router.navigate(['vaccine-schedule/admin']);
      this.router.navigate(['facility-admin']);
    }
    //admin
    if (context.viewType == 'r3ap') {
      this._dateService.vaccineContext.setContext(context);
      this.loaderService.showLoader();
      // this.router.navigate(['vaccine-schedule/admin']);
      this.router.navigate(['r3ap']);
    }

    if (context.viewType == 'oruser') {
      this._dateService.vaccineContext.setContext(context);
      this.loaderService.showLoader();
      this.router.navigate(['outreach-user']);
    }

  }

}




  
