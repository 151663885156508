import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  AlertsService, DataService, LoaderService, UserService } from '../../shared';
import { ActivatedRoute,Router } from '@angular/router';
import { VaccineContext } from 'src/app/shared/models/vaccine-context.model';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { MessageBoxService } from 'src/app/shared/services/message-box.service';
import { Location } from '@angular/common';
import { DomainValuesService } from "src/app/shared/services/domain-values.service";

@Component({
  selector: 'app-mfa-code',
  templateUrl: './mfa-code.component.html',
  styleUrls: ['./mfa-code.component.css']
})
export class MFACodeComponent implements OnInit {

  mfaCode = new FormControl('', [Validators.required]);
  processing = false;
  authForm: FormGroup;
  expirationTimeInMinutes ='30';
  constructor(

    private loaderService: LoaderService,

    private userService: UserService,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private _dataService: DataService,
    private _navigationService: NavigationService,
    private _msgService: MessageBoxService,
    private location: Location,
    private _domainValueService: DomainValuesService,
    public router: Router) {

    this.authForm = this.fb.group({
      MFACode: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(10), Validators.required]),
    });    
  }

  ngOnInit(): void {
    this.alertsService.clearLoader();
    this.getPINExpirationValue();
  }


  async onMfaCodeSubmit() {
    var user = this._dataService.vaccineContext.getContext().contextUser;
    this.processing = true;
    this.mfaCode.disable();
    let mfaCode = this.authForm.value.MFACode.toString().toUpperCase();
    this.userService.verifyMFA({ UserName: user.userName, MfaCode: mfaCode }).subscribe((context: VaccineContext) => {
      this._navigationService.navigateToViewType(context);
      this.processing = false;
    },
      resp => {
        if (resp.error) {

          this.alertsService.showErrorMessage(resp.error);
          this.processing = false;
        }
        this.processing = false;
      },

      () => {
        this.processing = false;
      }

    );
  }

  onResendMfaCode() {
    var user = this._dataService.vaccineContext.getContext().contextUser;
    this.processing = true;
    this.userService.resendMFA(user.userName).subscribe(resp => {
      if (resp == true) {
        this.alertsService.showSucessMessage("The MFA PIN has been successfully sent to your registered email address.", 'Success');
        this.processing = false;
      }
      else {
        this.alertsService.showErrorMessage('Error occured', 'Failed');
        this.processing = false;
      }
    },
      resp => {
        if (resp.error) {
          this.alertsService.showErrorMessage(resp.error);
          this.processing = false;
        }
        this.processing = false;
      },

      () => {
        this.processing = false;
      });
  }

  onBackToLogin() {
    this.userService.logOff();
    this.router.navigate(['']);
  }

  getPINExpirationValue() {
    this._domainValueService.getDomainValues("EMAIL_MFA_PIN_EXPIRY_TIME_MINUTES").subscribe(
      (values) => {
        this.expirationTimeInMinutes = values?.[0]?.value ?? '30';
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.loaderService.hideLoader();
      }
    );
  }

}
